import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	IPageWillMountHandler,
	IPropsStore,
	PageFeatureConfigSymbol,
	Props,
	CurrentRouteInfoSymbol,
	MasterPageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { ScrollToAnchorMasterPageConfig, ScrollToAnchorPageConfig } from './types'
import { name } from './symbols'
import { ICurrentRouteInfo } from 'feature-router'

const samePageAnchorHrefUpdaterFactory = (
	pageConfig: ScrollToAnchorPageConfig,
	masterPageConfig: ScrollToAnchorMasterPageConfig,
	propsStore: IPropsStore,
	currentRouteInfo: ICurrentRouteInfo
): IPageWillMountHandler => {
	return {
		pageWillMount: async () => {
			const { relativeUrl } = currentRouteInfo.getCurrentRouteInfo()!
			const isHomePageCurrentRoute = relativeUrl === './'

			// no need to update href of comps with top/bottom anchor on homepage because it is already
			// resolved correctly in site assets server for this flow
			const compsWithTopBottomAnchor = isHomePageCurrentRoute ? [] : masterPageConfig.compsWithTopBottomAnchor

			const compsToUpdate = [
				...pageConfig.compsWithCurrentInnerRouteDynamicPageLink,
				...masterPageConfig.compsWithCurrentInnerRouteDynamicPageLink,
				...compsWithTopBottomAnchor,
			]

			if (compsToUpdate.length > 0) {
				const updatedProps = compsToUpdate.reduce((propsToUpdate, compId) => {
					const currentLink = propsStore.get(compId).link
					return {
						...propsToUpdate,
						...{
							[compId]: {
								link: {
									...currentLink,
									href: relativeUrl,
								},
							},
						},
					}
				}, {})

				propsStore.update(updatedProps)
			}
		},
	}
}

export const SamePageAnchorHrefUpdater = withDependencies(
	[named(PageFeatureConfigSymbol, name), named(MasterPageFeatureConfigSymbol, name), Props, CurrentRouteInfoSymbol],
	samePageAnchorHrefUpdaterFactory
)
